import React from "react"
import SinglePageHeader from "../components/SinglePageLayout"

const NotFoundPage = () => (
  <SinglePageHeader
    title="404 : Page Not Found"
    bg="https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1952&q=80"
  >
    <main style={{ textAlign: "center", padding: 20 }}>
      <i className="fa fa-search" style={{ fontSize: "3em" }} />
      <h1>Page Not Found</h1>
      <p>We could not find the page your are looking for.</p>
    </main>
  </SinglePageHeader>
)

export default NotFoundPage
